/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

/*-----------------------------------*\
  #style.css
\*-----------------------------------*/

/**
 * copyright 2022 codewithsadee
 */





/*-----------------------------------*\
  #CUSTOM PROPERTY
\*-----------------------------------*/

:root {

  /**
   * colors
   */

  --safety-orange: hsl(25, 100%, 50%);
  --lavender-gray: hsl(230, 19%, 81%);
  --persian-rose: hsl(328, 100%, 59%);
  --red-crayola: hsl(341, 100%, 49%);
  --eerie-black: hsl(240, 6%, 10%);
  --light-gray: hsl(0, 0%, 80%);
  --cultured-2: hsl(210, 60%, 98%);
  --platinum: hsl(0, 0%, 90%);
  --gray-web: hsl(220, 5%, 49%);
  --cultured: hsl(0, 0%, 93%);
  --black_10: hsla(0, 0%, 0%, 0.1);
  --black_5: hsla(0, 0%, 0%, 0.05);
  --white-1: hsl(0, 0%, 100%);
  --white-2: hsl(0, 14%, 98%);
  --black: hsl(0, 0%, 0%);

  /**
   * gradient color
   */

  --gradient: linear-gradient(to left top, var(--persian-rose), var(--safety-orange));

  /**
   * typography
   */

  --ff-roboto: 'Roboto', sans-serif;
  --ff-league-spartan: 'League Spartan', sans-serif;

  --fs-1: 3.5rem;
  --fs-2: 3rem;
  --fs-3: 2.1rem;
  --fs-4: 1.7rem;
  --fs-5: 1.4rem;
  --fs-6: 1.3rem;

  --fw-700: 700;
  --fw-500: 500;

  /**
   * spacing
   */

  --section-padding: 60px;

  /**
   * shadow
   */

  --shadow-1: 0 6px 24px var(--black_5);
  --shadow-2: 0 2px 28px var(--black_10);

  /**
   * border radius
   */

  --radius-2: 2px;
  --radius-5: 5px;
  --radius-8: 8px;

  /**
   * transition
   */

  --transition-1: 0.25s ease;
  --transition-2: 0.5s ease;
  --cubic-out: cubic-bezier(0.33, 0.85, 0.4, 0.96);

}





/*-----------------------------------*\
  #RESET
\*-----------------------------------*/

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

a,
img,
time,
label,
input,
button,
textarea,
ion-icon {
  display: block;
}

img {
  height: auto;
}

input,
button,
textarea {
  background: none;
  border: none;
  font: inherit;
}

input,
textarea {
  width: 100%;
}

button {
  cursor: pointer;
}

ion-icon {
  pointer-events: none;
}

address {
  font-style: normal;
}

html {
  font-family: var(--ff-roboto);
  font-size: 10px;
  scroll-behavior: smooth;
}

body {
  background-color: var(--white-1);
  color: var(--gray-web);
  font-size: 1.6rem;
  line-height: 1.8;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: hsl(0, 0%, 98%);
}

::-webkit-scrollbar-thumb {
  background-color: hsl(0, 0%, 80%);
}

::-webkit-scrollbar-thumb:hover {
  background-color: hsl(0, 0%, 70%);
}

:focus-visible {
  outline-offset: 4px;
}





/*-----------------------------------*\
  #REUSED STYLE
\*-----------------------------------*/

.container {
  padding-inline: 15px;
}

.btn {
  max-width: max-content;
  color: var(--white-1);
  font-size: var(--fs-6);
  font-weight: var(--fw-700);
  padding: 10px 30px;
  border-radius: var(--radius-5);
  transition: var(--transition-1);
}

.btn-primary {
  background-image: var(--gradient);
  background-size: 1000%;
}

.btn-primary:is(:hover, :focus) {
  background-position: bottom right;
}

.btn-secondary {
  background-color: var(--white-1);
  color: var(--eerie-black);
}

.btn-secondary:is(:hover, :focus) {
  background-color: var(--eerie-black);
  color: var(--white-1);
}

.section {
  padding-block: var(--section-padding);
}

.h1,
.h2,
.h3 {
  color: var(--eerie-black);
  font-family: var(--ff-league-spartan);
  line-height: 1.2;
}

.h1 {
  font-size: var(--fs-1);
}

.h2 {
  font-size: var(--fs-2);
}

.h3 {
  font-size: var(--fs-3);
}

.w-100 {
  width: 100%;
}

.section-title,
.section-text {
  text-align: center;
}

.section-text {
  font-size: var(--fs-6);
}

.grid-list {
  display: grid;
  gap: 30px;
}

.img-holder {
  aspect-ratio: var(--width) / var(--height);
  background-color: var(--light-gray);
}

.img-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


/*-----------------------------------*\
  #HEADER
\*-----------------------------------*/

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: var(--white-1);
  padding-block: 15px;
  border-block-end: 1px solid var(--cultured);
  z-index: 4;
  transition: var(--transition-1);
}

.header.active {
  filter: drop-shadow(var(--shadow-2));
}

.header>.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* .logo-text {
  font-family: var(--ff-league-spartan);
  color: var(--black);
  font-size: 3.5rem;
  font-weight: var(--fw-700);
  line-height: 1;
} */

.logo {
  height: 4rem;
}

.nav-toggle-btn {
  font-size: 40px;
}

/* .nav-toggle-btn.active .open,
.nav-toggle-btn .close {
  display: none;
} */

/* .nav-toggle-btn .open,
.nav-toggle-btn.active .close {
  display: block;
} */

.navbar {
  background-color: var(--white-1);
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  max-width: calc(100% - 30px);
  width: 100%;
  padding-inline: 0;
  border: 1px solid var(--cultured);
  transition: 0.3s var(--cubic-out);
  max-height: 0;
  visibility: hidden;
  overflow: hidden;
}

.navbar.active {
  max-height: 320px;
  visibility: visible;
  transition-duration: 0.5s;
}

.navbar-list {
  padding: 20px 15px;
  padding-block-start: 10px;
  opacity: 0;
  transition: var(--transition-1);
}

.navbar.active .navbar-list {
  opacity: 1;
}

.navbar-link {
  color: var(--eerie-black);
  font-family: var(--ff-league-spartan);
  font-size: var(--fs-4);
  line-height: 1.2;
  padding-block: 8px;
  transition: var(--transition-1);
}

.navbar-link:is(:hover, :focus) {
  color: var(--red-crayola);
}

.navbar-link.active {
  color: var(--red-crayola);
}

.header .btn {
  margin-block-start: 10px;
}





/*-----------------------------------*\
  #HEO
\*-----------------------------------*/

.hero {
  padding-block-start: calc(var(--section-padding) + 50px);
  text-align: center;
  min-height: 90vh;
}

.hero-content {
  margin-block-end: 30px;
}

.hero-subtitle {
  color: var(--eerie-black);
  font-size: var(--fs-5);
  font-weight: var(--fw-500);
}

.hero-title {
  margin-block: 12px 8px;
}

.hero-text {
  font-size: var(--fs-5);
}

.hero .btn {
  margin-inline: auto;
  margin-block-start: 20px;
}





/*-----------------------------------*\
  #SERVICE
\*-----------------------------------*/

.service {
  background-color: var(--white-2);
  padding-block-start: calc(var(--section-padding) + 50px);
}

.service .section-text {
  margin-block: 5px 35px;
}

.service-card {
  background-color: var(--white-1);
  padding: 20px 15px;
  border: 1px solid var(--platinum);
  border-radius: var(--radius-5);
  text-align: center;
  box-shadow: var(--shadow-1);
  transition: var(--transition-2);
}

.service-card:is(:hover, :focus-within) {
  transform: translateY(-10px);
}

.service-card .card-icon {
  color: var(--white-1);
  font-size: 25px;
  max-width: max-content;
  margin-inline: auto;
  padding: 18px;
  border-radius: 50%;
}

.service-card .card-title {
  margin-block: 20px 8px;
}

.service-card .card-text {
  font-size: var(--fs-6);
}





/*-----------------------------------*\
  #PROJECT
\*-----------------------------------*/

.project .section-text {
  margin-block: 5px 35px;
}

.project {
  padding-block-start: calc(var(--section-padding) + 50px);
}

.project-card {
  position: relative;
  border-radius: var(--radius-8);
  overflow: hidden;
}

.project-card::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--black);
  opacity: 0.5;
  transition: var(--transition-2);
}

.project-card:is(:hover, :focus-within)::after {
  opacity: 0.8;
}

.project-card .card-content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  z-index: 1;
  transition: var(--transition-2);
}

.project-card:is(:hover, :focus-within) .card-content {
  transform: translateY(-20px);
}

.project-card .card-subtitle {
  color: var(--lavender-gray);
  font-size: var(--fs-6);
  line-height: 1;
}

.project-card .card-title {
  color: var(--white-1);
  margin-block: 12px 15px;
}



/*-----------------------------------*\
  #TESTIMONIAL
\*-----------------------------------*/

.container--testimonial {
  display: grid;
}

.testimonials-video-wrapper {
  max-width: calc(60vh * 16 / 9);
  width: 100%;
  max-height: 60vh;
  margin: 0 auto;

  /* only on mobile */
  margin-bottom: 25px;
}



.testimonials-video-iframe {
  width: 100%;
  position: relative;
  /* 16:9 aspect ratio */
  /* (padding-top = (9 / 16) * 100) => (padding-top = 56.25%) */
  padding-top: 56.25%;
  overflow: hidden;
}

.testimonials-video-iframe iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.review {
  margin-bottom: 5px;
  display: flex;
  gap: 2px;
  align-items: center;
  justify-content: center;
}

.review {
  ion-icon {
    color: #FA004F;
    font-size: var(--fs-4);
  }
}

.testimonials .section-title {
  margin-block: 0 12px;
}

.testimonials .section-text {
  text-align: center;
  color: #000;
  font-size: var(--fs-3);
  margin-block-end: 10px;

}

.testimonial-author {
  font-size: var(--fs-5);
}

/*-----------------------------------*\
  #ABOUT
\*-----------------------------------*/

.container--about {
  display: grid;
}

.about-banner {
  margin: 0 auto;
}

.about-content {
  grid-row: 1/2;
  margin-bottom: 25px;
}

.about {
  background-color: var(--white-2);
}

.about-banner {
  position: relative;
  border-radius: var(--radius-5);
  overflow: hidden;
  margin-block-end: 25px;
}

.play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--white-1);
  color: var(--red-crayola);
  font-size: 32px;
  padding: 16px;
  border-radius: 50%;
  animation: pulse 1.5s ease-out infinite;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 1px var(--white-1);
  }

  100% {
    box-shadow: 0 0 0 25px transparent;
  }
}

.about :is(.section-title, .section-text) {
  text-align: left;
}

.about .section-title {
  margin-block-end: 5px;
}

.about .section-text:not(:last-child) {
  margin-block-end: 18px;
}

.about .h3 {
  margin-block-end: 8px;
}

.about-list {
  margin-block: 15px 18px;
}

.about-item {
  display: flex;
  align-items: flex-start;
  gap: 5px;
}

.about-item ion-icon {
  color: var(--red-crayola);
  flex-shrink: 0;
  margin-block-start: 2px;
}

.about-item:not(:last-child) {
  margin-block-end: 12px;
}



/*-----------------------------------*\
  #VIDEO
\*-----------------------------------*/

.video {
  background-color: var(--white-2);

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.video .section-text {
  margin-block: 5px 35px;
}

.video-wrapper {
  max-width: calc(60vh * 16 / 9);
  width: 95vw;
  max-height: 60vh;
  margin: 0 auto;
}

.video-iframe {
  width: 100%;
  position: relative;
  /* 16:9 aspect ratio */
  /* (padding-top = (9 / 16) * 100) => (padding-top = 56.25%) */
  padding-top: 56.25%;
  overflow: hidden;
}

.video-iframe iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}


/*-----------------------------------*\
  #TEAM
\*-----------------------------------*/

.team .section-text {
  margin-block: 5px 35px;
}

.our-team {
  padding: 30px 0 40px;
  margin-bottom: 30px;
  background-color: var(--white-2);
  text-align: center;
  overflow: hidden;
  position: relative;
}

.our-team .picture {
  display: inline-block;
  height: 130px;
  width: 130px;
  margin-bottom: 50px;
  z-index: 1;
  position: relative;
}

.our-team .picture::before {
  content: "";
  width: 100%;
  height: 0;
  border-radius: 50%;
  background-color: var(--red-crayola);
  position: absolute;
  bottom: 135%;
  right: 0;
  left: 0;
  opacity: 0.9;
  transform: scale(3);
  transition: all 0.3s linear 0s;
}

.our-team:hover .picture::before {
  height: 100%;
}

.our-team .picture::after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  /*   background-color: var(--white-2); */
  background-color: var(--red-crayola);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.our-team .picture img {
  width: 100%;
  height: auto;
  border-radius: 50%;
  transform: scale(1);
  transition: all 0.9s ease 0s;
}

.our-team:hover .picture img {
  box-shadow: 0 0 0 14px var(--white-2);
  transform: scale(0.7);
}

.our-team .title {
  display: block;
  font-size: 15px;
  color: #4e5052;
  text-transform: capitalize;
}

.our-team .social {
  width: 100%;
  padding: 0;
  margin: 0;
  background-color: var(--red-crayola);
  position: absolute;
  bottom: -100px;
  left: 0;
  transition: all 0.5s ease 0s;
}

.our-team:hover .social {
  bottom: 0;
}

.our-team .social {
  display: inline-block;
}

.our-team .social a {
  display: block;
  padding: 10px;
  font-size: 17px;
  color: white;
  transition: all 0.3s ease 0s;
  text-decoration: none;
}

.our-team .social a:hover {
  color: var(--white-2);
  background-color: var(--persian-rose);
}

/* Added Custom Code */
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  align-items: center;
  justify-content: center;
}

.row__item {
  flex: 0 0 260px;
  padding-left: 15px;
  padding-right: 15px;
}

/*-----------------------------------*\
  #CTA
\*-----------------------------------*/

.cta {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  z-index: 1;
}

.cta::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: var(--gradient);
  opacity: 0.95;
  z-index: -1;
}

.cta-subtitle,
.cta .section-title {
  color: var(--white-1);
}

.cta-subtitle {
  font-size: var(--fs-6);
  font-weight: var(--fw-500);
  text-align: center;
}

.cta .section-title {
  margin-block: 12px 18px;
}

.cta .btn {
  margin-inline: auto;
}





/*-----------------------------------*\
  #BLOG
\*-----------------------------------*/

.blog .section-text {
  margin-block: 5px 35px;
}

.blog-card {
  background-color: var(--white-2);
}

.blog-card .card-banner {
  overflow: hidden;
}

.blog-card .card-banner img {
  transition: var(--transition-2);
}

.blog-card:is(:hover, :focus-within) .card-banner img {
  transform: scale(1.05);
}

.blog-card .card-content {
  padding: 15px;
}

.blog-card .time {
  color: var(--red-crayola);
  font-size: var(--fs-6);
  line-height: 1;
  margin-block-end: 10px;
}

.blog-card .card-title {
  transition: var(--transition-1);
}

.blog-card .card-title:is(:hover, :focus) {
  color: var(--red-crayola);
}





/*-----------------------------------*\
  #CONTACT
\*-----------------------------------*/

.contact {
  background-color: var(--white-2);
  padding-block-start: calc(var(--section-padding) + 50px);
}

.contact .section-text {
  margin-block: 5px 35px;
}

.contact-form {
  background-color: var(--white-1);
  padding: 20px;
  border-radius: var(--radius-2);
  margin-block-end: 30px;
  box-shadow: var(--shadow-1);
}

.input-field {
  background-color: var(--white-2);
  color: var(--eerie-black);
  font-size: var(--fs-5);
  padding: 15px;
  border-radius: var(--radius-2);
  outline: 1px solid transparent;
  outline-offset: 0;
  margin-block-end: 15px;
}

.input-field::-webkit-inner-spin-button {
  display: none;
}

.input-field:focus {
  outline-color: var(--red-crayola);
}

.input-field::placeholder {
  transition: var(--transition-1);
}

.input-field:focus::placeholder {
  opacity: 0;
}

textarea.input-field {
  resize: vertical;
  min-height: 80px;
  height: 100px;
  max-height: 200px;
  overscroll-behavior: contain;
}

.checkbox {
  width: max-content;
  margin-block-start: 5px;
  accent-color: var(--red-crayola);
}

.label-link {
  display: inline-block;
  color: var(--red-crayola);
}

.label-link:is(:hover, :focus) {
  text-decoration: underline;
}

.checkbox-wrapper {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  margin-block-end: 15px;
}

.checkbox-wrapper .label {
  font-size: var(--fs-6);
}

.contact-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px 25px;
}

.contact-item {
  min-width: 100%;
}

.contact-card {
  background-color: var(--white-1);
  padding: 20px;
  border-radius: var(--radius-2);
  box-shadow: var(--shadow-1);
  display: flex;
  align-items: flex-start;
  gap: 15px;
}

.contact-card .card-icon {
  background-color: var(--cultured-2);
  color: var(--red-crayola);
  font-size: 25px;
  padding: 13px;
  border-radius: 50%;
  transition: var(--transition-1);
}

.contact-card:is(:hover, :focus) .card-icon {
  background-color: var(--red-crayola);
  color: var(--white-1);
}

.contact-card .card-title {
  margin-block-end: 5px;
}

.contact-card :is(.card-link, .card-address) {
  font-size: var(--fs-6);
  transition: var(--transition-1);
}

.contact-card .card-link:is(:hover, :focus) {
  color: var(--red-crayola);
}





/*-----------------------------------*\
  #FOOTER
\*-----------------------------------*/

/* CUSTOM EDITED */
.footer {
  padding-block: 20px;
}

.footer--gray {
  background-color: var(--white-2);
}

.copyright {
  font-size: var(--fs-6);
  text-align: center;
}

.copyright-link {
  color: var(--red-crayola);
  font-weight: var(--fw-500);
  display: inline-block;
}





/*-----------------------------------*\
  #BACK TO TOP
\*-----------------------------------*/

.back-top-btn {
  position: fixed;
  bottom: 10px;
  right: 20px;
  background-color: var(--eerie-black);
  color: var(--white-1);
  padding: 12px;
  border-radius: 50%;
  box-shadow: var(--shadow-2);
  z-index: 4;
  opacity: 0;
  visibility: hidden;
  transition: var(--transition-1);
}

.back-top-btn.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(-10px);
}

.back-top-btn:is(:hover, :focus) {
  opacity: 0.9;
}





/*-----------------------------------*\
  #MEDIA QUERIES
\*-----------------------------------*/

/**
 * responsive for larger than 575px screen
 */

@media (min-width: 575px) {

  /**
   * REUSED STYLE
   */

  .container {
    max-width: 540px;
    width: 100%;
    margin-inline: auto;
  }

  .grid-list {
    grid-template-columns: 1fr 1fr;
    column-gap: 25px;
  }



  /**
   * BLOG
   */

  .blog .grid-list {
    grid-template-columns: 1fr;
  }

  .blog-card.grid {
    display: grid;
    grid-template-columns: 0.47fr 1fr;
    align-items: center;
    gap: 10px;
  }



  /**
   * CONTACT
   */

  .input-wrapper {
    display: flex;
    gap: 15px;
  }

  /* .contact-item {
    min-width: calc(50% - 18px);
  } */

}





/**
 * responsive for larger than 768px screen
 */

@media (min-width: 768px) {

  /**
   * CUSTOM PROPERTY
   */

  :root {

    /**
     * typography
     */

    --fs-1: 4.5rem;
    --fs-2: 3.7rem;
    --fs-3: 2.3rem;
    --fs-4: 1.8rem;
    --fs-5: 1.5rem;
    --fs-6: 1.4rem;

  }



  /**
   * REUSED STYLE
   */

  .container {
    max-width: 720px;
  }

  .section-text {
    max-width: 65ch;
    margin-inline: auto;
  }



  /**
   * HERO
   */

  .hero-text {
    max-width: 60ch;
    margin-inline: auto;
  }

  /**
   * TESTIMONIALS
   */

  /* .testimonials-video-wrapper {
    width: 100%;
  } */


  /**
   * ABOUT
   */

  .about-banner {
    max-width: 550px;
  }

  .about .section-text {
    max-width: unset;
    margin-inline: 0;
  }



  /**
   * CONTACT
   */

  .contact-form {
    padding: 30px;
  }

  /* .contact-item {
    min-width: calc(50% - 12.5px);
  } */

}





/**
 * responsive for larger than 992px screen
 */

@media (min-width: 992px) {

  /**
   * CUSTOM PROPERTY
   */

  :root {

    /**
     * typography
     */

    --fs-1: 5rem;
    --fs-2: 4rem;
    --fs-3: 2.5rem;

    /**
     * spacing
     */

    --section-padding: 100px;

  }



  .logo {
    height: 4.7rem;
  }



  /**
   * REUSED STYLE
   */

  .container {
    max-width: 960px;
  }

  .grid-list {
    grid-template-columns: repeat(3, 1fr);
  }



  /**
   * HEADER
   */

  .nav-toggle-btn {
    display: none;
  }

  .navbar,
  .navbar.active {
    all: unset;
  }

  .navbar-list {
    all: unset;
    display: flex;
    align-items: center;
    gap: 30px;
  }

  .navbar-link {
    position: relative;
  }

  .navbar-link:is(:hover, :focus) {
    color: var(--eerie-black);
  }


  /* ADDED THIS */
  .navbar-link.active {
    color: var(--eerie-black);
  }

  .navbar-link::after,
  .navbar-link.active::after {
    content: "";
    position: absolute;
    bottom: 5px;
    left: 0;
    width: 100%;
    height: 2px;
    background-image: var(--gradient);
    transform: scaleX(0);
    transform-origin: left;
    transition: var(--transition-2);
  }

  /* REMOVED THIS */
  /* .navbar-link:is(:hover, :focus)::after {
    transform: scaleX(1);
  } */

  .navbar-link:hover::after,
  .navbar-link:focus::after,
  .navbar-link.active::after {
    transform: scaleX(1);
  }

  .header .btn {
    margin-block-start: 0;
  }



  /**
   * HERO
   */

  .hero .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 30px;
    text-align: left;
  }

  .hero-content {
    margin-block-end: 0;
  }

  .hero-subtitle {
    position: relative;
    padding-inline-start: 15px;
  }

  .hero-subtitle::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 2px;
    height: 100%;
    background-color: var(--red-crayola);
  }

  .hero-text,
  .hero .btn {
    margin-inline: 0;
  }



  /**
   * SERVICE
   */

  .service-card {
    padding: 20px;
  }

  .service-card .h3 {
    --fs-3: 2.3rem;
  }



  /**
   * PROJECT
   */

  .project-card .card-subtitle {
    --fs-6: 1.5rem;
  }

  /**
   * TESTIMONIAL
   */

  .testimonials .section-title {
    margin-block: unset;
    margin-block: 5px 35px;
  }

  .testimonials .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 30px;
  }

  .testimonials-video-wrapper {
    margin-bottom: 0;
  }

  /**
   * ABOUT
   */

  .about .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 30px;
  }

  .about-content {
    grid-column: 1/2;
    margin-bottom: 0;
  }

  .about-banner {
    margin-block-end: 0;
  }



  /**
   * BLOG
   */

  .blog .grid-list {
    grid-template-columns: 1fr 1fr;
    row-gap: 20px;
  }

  .blog .grid-list>li:first-child {
    grid-column: 1 / 2;
    grid-row: 1 / 5;
  }

  .blog-card:not(.grid) {
    height: 100%;
  }

  .blog-card .card-content {
    padding-block: 10px;
  }

  .blog-card:not(.grid) .card-content {
    padding: 25px;
  }

  .blog-card.grid .h3 {
    --fs-3: 2rem;
  }

  .blog-card .time {
    --fs-6: 1.6rem;
  }



  /**
   * CONTACT
   */

  .checkbox-wrapper {
    margin-block: 20px;
  }

  .contact-item {
    /* min-width: calc(33.33% - 16.66px); */
    min-width: calc(50% - 16.66px);
  }

  .contact-card {
    padding: 30px;
  }

  .contact-card .card-icon {
    font-size: 32px;
  }

}





/**
 * responsive for larger than 1200px screen
 */

@media (min-width: 1200px) {

  /**
   * CUSTOM PROPERTY
   */

  :root {

    /**
     * typography
     */

    /* --fs-1: 6.8rem; */
    --fs-1: 6.4rem;
    --fs-2: 4.5rem;
    --fs-4: 1.9rem;
    --fs-5: 1.6rem;
    --fs-6: 1.6rem;

  }



  /**
   * REUSED STYLE
   */

  .container {
    max-width: 1140px;
  }

  .btn {
    --fs-6: 1.5rem;
  }



  /**
   * SERVICE
   */

  .service-card {
    padding: 30px;
  }

  .service-card .h3 {
    --fs-3: 2.5rem;
  }



  /**
   * PROJECT
   */

  .project-card .card-content {
    padding: 30px;
  }

  .project-card .card-subtitle {
    --fs-6: 1.6rem;
  }



  /**
   * ABOUT
   */

  .about .container {
    gap: 60px;
  }

  .about-item {
    gap: 7px;
  }

  .about-item ion-icon {
    font-size: 20px;
  }



  /**
   * CTA
   */

  .cta .section-title {
    margin-block: 15px 24px;
  }



  /**
   * BLOG
   */

  .blog-card .time {
    --fs-6: 1.6rem;
  }

  .blog-card.grid .h3 {
    --fs-3: 2.4rem;
  }



  /**
   * CONTACT
   */

  .contact-form {
    padding: 50px;
  }

  .contact-card {
    gap: 20px;
  }

  .contact-card .card-icon {
    padding: 15px;
  }

}